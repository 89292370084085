var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "permission",
            rawName: "v-permission",
            value: "dashboard",
            expression: "'dashboard'",
          },
        ],
      },
      [
        _c("drop-list", {
          staticClass: "grid grid-rows-5 grid-cols-12 gap-4 h-screen pt-4",
          style: _vm.panelHeight,
          attrs: { items: _vm.cards },
          on: {
            reorder: function ($event) {
              return $event.apply(_vm.cards)
            },
            insert: _vm.insert,
          },
          scopedSlots: _vm._u([
            {
              key: "item",
              fn: function (ref) {
                var item = ref.item
                var reorder = ref.reorder
                return [
                  _c(
                    "drag",
                    {
                      key: item.id,
                      class:
                        "col-span-12 lg:col-span-" +
                        item.columns +
                        " row-span-" +
                        item.rows,
                      attrs: { data: item, handle: ".handleCard" },
                      on: {
                        cut: function ($event) {
                          return _vm.remove(_vm.cards, item)
                        },
                        dragstart: function ($event) {
                          return _vm.dragStart(item)
                        },
                        dragend: function ($event) {
                          return _vm.dragEnd(item)
                        },
                      },
                    },
                    [
                      _c(
                        "vs-list",
                        { staticClass: "h-full no-pad" },
                        [
                          _c(
                            "vs-list-item",
                            {
                              staticClass: "h-full no-pad",
                              style: reorder ? {} : {},
                              on: {
                                "item-DELETE_ITEM": function ($event) {
                                  return _vm.deleteItem($event)
                                },
                                "item-DUPLICATE_ITEM": function ($event) {
                                  return _vm.duplicateItem($event)
                                },
                                "item-ITEM_UPDATED": function ($event) {
                                  return _vm.updateItem($event)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "handleCard",
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.handleEnter(item)
                                    },
                                    mouseleave: function ($event) {
                                      return _vm.handleLeave(item)
                                    },
                                  },
                                },
                                [
                                  _c("v-list-item-avatar", [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: item.handle && !_vm.dragging,
                                            expression:
                                              "item.handle && !dragging",
                                          },
                                        ],
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "grip-horizontal" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              item.type === "calendar"
                                ? _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [_c("SimpleCalendar")],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "feedback",
              fn: function (ref) {
                var data = ref.data
                return [
                  _c(
                    "div",
                    { key: data, staticClass: "item feedback" },
                    [
                      _c(
                        "vue-content-loading",
                        { attrs: { width: 300, height: 32 } },
                        [
                          _c("rect", {
                            attrs: {
                              x: "3",
                              y: "5",
                              rx: "4",
                              ry: "4",
                              width: "100",
                              height: "7",
                            },
                          }),
                          _c("rect", {
                            attrs: {
                              x: "3",
                              y: "15",
                              rx: "4",
                              ry: "4",
                              width: "50",
                              height: "7",
                            },
                          }),
                          _c("rect", {
                            attrs: {
                              x: "3",
                              y: "25",
                              rx: "4",
                              ry: "4",
                              width: "75",
                              height: "7",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }