var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { top: "0", width: "100%", position: "absolute" },
      attrs: { id: "simple-calendar-app" },
    },
    [
      _c(
        "div",
        { staticClass: "flex w-full items-center justify-center pb-2" },
        [_c("h5", [_vm._v(_vm._s(_vm.$t("calendario")))])]
      ),
      _c(
        "div",
        {
          staticClass: "vx-card no-scroll-content",
          staticStyle: {
            "max-height": "100%",
            "min-height": "500px",
            width: "100%",
          },
        },
        [
          _c(
            "calendar-view",
            {
              ref: "calendar",
              staticClass: "theme-default",
              attrs: {
                displayPeriodUom: _vm.calendarView,
                "show-date": _vm.showDate,
                events: _vm.simpleCalendarEvents,
                enableDragDrop: "",
                eventTop: _vm.windowWidth <= 400 ? "2rem" : "3rem",
                eventBorderHeight: "0px",
                eventContentHeight: "1.65rem",
              },
              on: {
                "click-date": _vm.openAddNewEvent,
                "click-event": _vm.handleEventClick,
                "drop-on-date": _vm.eventDragged,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "mb-4",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("div", { staticClass: "vx-row no-gutter" }, [
                    _c(
                      "div",
                      {
                        staticClass: "vx-col w-1/3 items-center sm:flex hidden",
                      },
                      [
                        _c(
                          "vs-button",
                          {
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-plus",
                            },
                            on: {
                              click: function ($event) {
                                _vm.promptAddNewEvent(new Date())
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("adicionar")))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-col sm:w-1/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex items-center" },
                          [
                            _c("feather-icon", {
                              staticClass:
                                "cursor-pointer bg-primary text-white rounded-full",
                              attrs: {
                                icon: _vm.$vs.rtl
                                  ? "ChevronRightIcon"
                                  : "ChevronLeftIcon",
                                svgClasses: "w-5 h-5 m-1",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updateMonth(-1)
                                },
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "mx-3 text-xl font-medium whitespace-no-wrap",
                              },
                              [_vm._v(_vm._s(_vm._f("month")(_vm.showDate)))]
                            ),
                            _c("feather-icon", {
                              staticClass:
                                "cursor-pointer bg-primary text-white rounded-full",
                              attrs: {
                                icon: _vm.$vs.rtl
                                  ? "ChevronLeftIcon"
                                  : "ChevronRightIcon",
                                svgClasses: "w-5 h-5 m-1",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.updateMonth(1)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-col sm:w-1/3 w-full flex justify-center",
                      },
                      [
                        _vm._l(_vm.calendarViewTypes, function (view, index) {
                          return [
                            _vm.calendarView === view.val
                              ? _c(
                                  "vs-button",
                                  {
                                    key: String(view.val) + "filled",
                                    staticClass: "p-3 md:px-8 md:py-3",
                                    class: {
                                      "border-l-0 rounded-l-none": index,
                                      "rounded-r-none":
                                        _vm.calendarViewTypes.length !==
                                        index + 1,
                                    },
                                    staticStyle: {
                                      "padding-left": "1rem !important",
                                    },
                                    attrs: { type: "filled" },
                                    on: {
                                      click: function ($event) {
                                        _vm.calendarView = view.val
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(view.shortLabel))]
                                )
                              : _c(
                                  "vs-button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top",
                                        value: {
                                          content: view.label,
                                          delay: { show: 1000, hide: 100 },
                                        },
                                        expression:
                                          "{\n                  content: view.label,\n                  delay: { show: 1000, hide: 100 }\n                }",
                                        modifiers: { top: true },
                                      },
                                    ],
                                    key: String(view.val) + "border",
                                    staticClass: "p-3 md:px-8 md:py-3",
                                    class: {
                                      "border-l-0 rounded-l-none": index,
                                      "rounded-r-none":
                                        _vm.calendarViewTypes.length !==
                                        index + 1,
                                    },
                                    staticStyle: {
                                      "padding-left": "1rem !important",
                                    },
                                    attrs: { type: "border" },
                                    on: {
                                      click: function ($event) {
                                        _vm.calendarView = view.val
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(view.shortLabel))]
                                ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "vx-row sm:flex hidden mt-4" }, [
                    _c("div", { staticClass: "vx-col w-full flex" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-wrap sm:justify-start justify-center",
                        },
                        [
                          _vm._l(_vm.calendarLabels, function (label, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "flex items-center mr-4 mb-2",
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "h-3 w-3 inline-block rounded-full mr-2",
                                  class: "bg-" + label.color,
                                }),
                                _c("span", [_vm._v(_vm._s(label.text))]),
                              ]
                            )
                          }),
                          _c(
                            "div",
                            { staticClass: "flex items-center mr-4 mb-2" },
                            [
                              _c("div", {
                                staticClass:
                                  "h-3 w-3 inline-block rounded-full mr-2 bg-primary",
                              }),
                              _c("span", [_vm._v(_vm._s(_vm.$t("none")))]),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "vs-prompt",
        {
          staticClass: "calendar-event-dialog",
          attrs: {
            title: _vm.$t("adicionar-evento"),
            "cancel-text": "Cancelar",
            "accept-text": "Adicionar",
            "is-valid": _vm.validForm,
            active: _vm.activePromptAddEvent,
          },
          on: {
            accept: _vm.addEvent,
            "update:active": function ($event) {
              _vm.activePromptAddEvent = $event
            },
          },
        },
        [
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            staticClass: "w-full",
            attrs: {
              name: "event-name",
              "label-placeholder": "Título do evento",
            },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
          _c(
            "div",
            { staticClass: "my-4" },
            [
              _c("small", { staticClass: "date-label" }, [
                _vm._v(_vm._s(_vm.$t("data-inicio"))),
              ]),
              _c("datepicker", {
                attrs: {
                  language: _vm.$vs.rtl ? _vm.langEn : _vm.langPt,
                  name: "start-date",
                  disabled: _vm.disabledFrom,
                },
                model: {
                  value: _vm.startDate,
                  callback: function ($$v) {
                    _vm.startDate = $$v
                  },
                  expression: "startDate",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "my-4" },
            [
              _c("small", { staticClass: "date-label" }, [
                _vm._v(_vm._s(_vm.$t("data-fim"))),
              ]),
              _c("datepicker", {
                attrs: {
                  language: _vm.$vs.rtl ? _vm.langEn : _vm.langPt,
                  disabledDates: _vm.disabledDatesTo,
                  name: "end-date",
                },
                model: {
                  value: _vm.endDate,
                  callback: function ($$v) {
                    _vm.endDate = $$v
                  },
                  expression: "endDate",
                },
              }),
            ],
            1
          ),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "url",
                expression: "'url'",
              },
            ],
            staticClass: "w-full mt-6",
            attrs: {
              name: "event-url",
              "label-placeholder": "URL do evento",
              color: !_vm.errors.has("event-url") ? "success" : "danger",
            },
            model: {
              value: _vm.url,
              callback: function ($$v) {
                _vm.url = $$v
              },
              expression: "url",
            },
          }),
        ],
        1
      ),
      _c(
        "vs-prompt",
        {
          staticClass: "calendar-event-dialog",
          attrs: {
            title: _vm.$t("editar-evento"),
            "accept-text": "Enviar",
            "cancel-text": "Remover",
            "button-cancel": "border",
            "is-valid": _vm.validForm,
            active: _vm.activePromptEditEvent,
          },
          on: {
            cancel: _vm.removeEvent,
            accept: _vm.editEvent,
            "update:active": function ($event) {
              _vm.activePromptEditEvent = $event
            },
          },
        },
        [
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'",
              },
            ],
            staticClass: "w-full",
            attrs: { name: "event-name", "label-placeholder": "Event Title" },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
          _c(
            "div",
            { staticClass: "my-4" },
            [
              _c("small", { staticClass: "date-label" }, [
                _vm._v(_vm._s(_vm.$t("start-date"))),
              ]),
              _c("datepicker", {
                attrs: {
                  language: _vm.langPt,
                  disabledDates: _vm.disabledDatesFrom,
                  name: "start-date",
                },
                model: {
                  value: _vm.startDate,
                  callback: function ($$v) {
                    _vm.startDate = $$v
                  },
                  expression: "startDate",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "my-4" },
            [
              _c("small", { staticClass: "date-label" }, [
                _vm._v(_vm._s(_vm.$t("end-date"))),
              ]),
              _c("datepicker", {
                attrs: {
                  language: _vm.langPt,
                  disabledDates: _vm.disabledDatesTo,
                  name: "end-date",
                },
                model: {
                  value: _vm.endDate,
                  callback: function ($$v) {
                    _vm.endDate = $$v
                  },
                  expression: "endDate",
                },
              }),
            ],
            1
          ),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "url",
                expression: "'url'",
              },
            ],
            staticClass: "w-full mt-6",
            attrs: {
              name: "event-url",
              "label-placeholder": "Event URL",
              color: !_vm.errors.has("event-url") ? "success" : "danger",
            },
            model: {
              value: _vm.url,
              callback: function ($$v) {
                _vm.url = $$v
              },
              expression: "url",
            },
          }),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "calendar-event-dialog",
          attrs: {
            title: _vm.$t("questionario-agendado"),
            active: _vm.activePromptViewEvent,
          },
          on: {
            "update:active": function ($event) {
              _vm.activePromptViewEvent = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-12" },
            [
              _c(
                "span",
                {
                  staticClass:
                    "text-lg text-center font-bold col-span-12 border-grey border-b border-t-0 border-r-0 border-l-0 border-solid",
                },
                [_vm._v(_vm._s(_vm.title))]
              ),
              _c(
                "div",
                { staticClass: "my-4 col-span-12 justify-self-start" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("fica-disponivel-de")))]),
                  _c("span", { staticClass: "mx-2 font-bold" }, [
                    _vm._v(_vm._s(_vm.startDate)),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("ate")))]),
                  _c("span", { staticClass: "mx-2 font-bold" }, [
                    _vm._v(_vm._s(_vm.endDate)),
                  ]),
                ]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "justify-self-end col-span-12",
                  attrs: { color: "primary" },
                  on: { click: _vm.openEventLink },
                },
                [_vm._v(_vm._s(_vm.$t("abrir")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }