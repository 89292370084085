import Rest from '../Rest'

export default class CalendarService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/calendar'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  events() {
    return this.get('events').then((data) => {
      return data
    })
  }

  scheduleEvent(event) {
    return this.post('event', event).then((data) => {
      return data
    })
  }

  updateEvent(id, data) {
    return this.post(`event/${id}`, data).then((response) => {
      return response
    })
  }

  removeEvent(id) {
    return this.delete(`event/${id}`).then(
      (response) => {
        return response
      }
    )
  }
}
