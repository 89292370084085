<template>
  <div>
    <div v-permission="'dashboard'">
      <drop-list
          :items="cards"
          @reorder="$event.apply(cards);"
          @insert="insert"
          :style="panelHeight"
          class="grid grid-rows-5 grid-cols-12 gap-4 h-screen pt-4"
        >
          <template v-slot:item="{ item, reorder }">
              <drag
                :key="item.id"
                :data="item"
                @cut="remove(cards, item)"
                handle=".handleCard"
                @dragstart="dragStart(item)"
                @dragend="dragEnd(item)"
                :class="`col-span-12 lg:col-span-${item.columns} row-span-${item.rows}`"
              >

                <vs-list class="h-full no-pad">
                  <vs-list-item
                    @item-DELETE_ITEM="deleteItem($event)"
                    @item-DUPLICATE_ITEM="duplicateItem($event)"
                    @item-ITEM_UPDATED="updateItem($event)"
                    class="h-full no-pad"
                    :style="
                      reorder
                        ? {}
                        : {}
                    "
                  >
                    <div
                      class="handleCard"
                      @mouseenter="handleEnter(item)"
                      @mouseleave="handleLeave(item)"
                    >
                      <v-list-item-avatar>
                        <div v-show="item.handle && !dragging">
                          <font-awesome-icon icon="grip-horizontal" />
                        </div>
                      </v-list-item-avatar>
                    </div>

                    <div 
                      v-if="item.type === 'calendar'"
                      class="flex"
                    >
                        <SimpleCalendar></SimpleCalendar>
                    </div>
                    
                  </vs-list-item>
                </vs-list>
              </drag>
          </template>

          <template v-slot:feedback="{ data }">
            <div class="item feedback" :key="data">
              <vue-content-loading :width="300" :height="32">
                <rect x="3" y="5" rx="4" ry="4" width="100" height="7" />
                <rect x="3" y="15" rx="4" ry="4" width="50" height="7" />
                <rect x="3" y="25" rx="4" ry="4" width="75" height="7" />
              </vue-content-loading>
            </div>
          </template>
      </drop-list>
    </div>
  </div>
</template>

<script>

import { Drag, DropList } from 'vue-easy-dnd'
import DashboardService from '@/services/api/DashboardService'
import SimpleCalendar from '@/layouts/components/calendar/SimpleCalendar'

export default {
  components: {
    Drag,
    DropList,
    SimpleCalendar
  },
  props: {
    
  },
  data() {
    return {
      dashboardCardService: null,
      dragging: false,
      cards: []
    }
  },
  methods: {
    deleteItem(item) {
      
    },
    duplicateItem(sourceQuestion) {
      
    },
    updateItem(item) {
    },
    dragStart(item) {
      this.dragging = true
    },
    dragEnd(item) {
      this.dragging = false
      this.cards.forEach((card, index) => {
        card.pos = index
      })

      this.dashboardService.updateDashboardCards({
        'cards': this.cards
      }).then(
        data => {
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    handleEnter(item) {
      item.handle = true
    },
    handleLeave(item) {
      item.handle = false
    }
  },
  computed: {
    panelHeight() {
      return '500px'
    }
  },
  mounted() {
    
  },
  beforeCreate() {
    this.dashboardService = DashboardService.build(this.$vs)

    this.$vs.loading()
    this.dashboardService.loadDashboardCards().then(
      data => {
        this.cards = data
        this.$vs.loading.close()
      },
      error => {
        this.$vs.loading.close()
      }
    )
  }
}
</script>

<style lang="scss">
.drop-allowed.drop-in * {
  cursor: inherit !important;
}

.handleCard {
  width: 100%;
  height: 20px;
  text-align: center;
  cursor: move;
  position: absolute;
  z-index: 9999999;
  top: 27px;
}

.handleCard > div {
  text-align: center;
}

.vs-list--slot {
  margin: 0;
  width: 100%;
}

.fa-grip-horizontal {
  color: lightgray;
  font-size: 16px;
}

.items-card .vs-button-text {
  font-size: 12px;
}

.item {
  padding: 20px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.feedback {
  border: 2px dashed white;
}

.drag-image {
  transform: translate(-50%, -50%);
}

.panel-item-btn i.vs-icon {
  font-size: 30px;
}

.land {
  @media only screen and (orientation: portrait) and (max-width: 555px) {
    transform: rotate(90deg);
    width: calc(155%);
    .content {
      width: calc(155%);
    }
  }

  .drop-list > .panel-margin {
    margin: 0 5px;
  }
}

.no-pad {
  padding: 0px !important;
}
</style>