/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from 'axios'
import CalendarService from '@/services/api/CalendarService'

export default {

  addEvent ({ commit, state }, event) {
    return new Promise((resolve, reject) => {
      state.service.scheduleEvent(event).then((data) => {
        commit('ADD_EVENT', data)
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  fetchEvents ({ commit, state }) {
    return new Promise((resolve, reject) => {
      state.service.events().then((data) => {
        commit('SET_EVENTS', data)
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  bootService({commit, state}, service) {
    if (state.service === null) {
      commit('SET_SERVICE', service)
    }
  },
  editEvent ({ commit, state }, event) {
    return new Promise((resolve, reject) => {

      event.startDate = new Date(event.startDate)
      event.endDate = new Date(event.endDate)

      state.service.updateEvent(event.id, event.data)
        .then((response) => {

          // Convert Date String to Date Object
          const event = response
          event.startDate = new Date(event.startDate)
          event.endDate = new Date(event.endDate)

          commit('UPDATE_EVENT', event)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeEvent ({ commit, state }, eventId) {
    return new Promise((resolve, reject) => {
      state.service.removeEvent(eventId)
        .then((response) => {
          commit('REMOVE_EVENT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  eventDragged ({ commit }, payload) {
    /*return new Promise((resolve, reject) => {
      axios.post(`/api/v1/calendar/event/dragged/${payload.event.id}`, {payload})
        .then((response) => {

          // Convert Date String to Date Object
          const event = response.data
          event.startDate = new Date(event.startDate)
          event.endDate = new Date(event.endDate)

          commit('UPDATE_EVENT', event)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })*/
  }
}
