import Rest from '../Rest'

export default class DashboardService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/dashboard'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  loadDashboardCards() {
    return this.get('/cards').then(data => {
      return data
    })
  }

  updateDashboardCards(cards) {
    return this.put('/cards', cards).then(data => {
      return data
    })
  }
}
