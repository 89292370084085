<template>
  <div id="simple-calendar-app" style="top: 0; width: 100%; position: absolute;">
    <div class="flex w-full items-center justify-center pb-2">
        <h5>{{ $t('calendario') }}</h5>
    </div>
    <div class="vx-card no-scroll-content" style="max-height: 100%; min-height: 500px; width: 100%;">
      <calendar-view
        ref="calendar"
        :displayPeriodUom="calendarView"
        :show-date="showDate"
        :events="simpleCalendarEvents"
        enableDragDrop
        :eventTop="windowWidth <= 400 ? '2rem' : '3rem'"
        eventBorderHeight="0px"
        eventContentHeight="1.65rem"
        class="theme-default"
        @click-date="openAddNewEvent"
        @click-event="handleEventClick"
        @drop-on-date="eventDragged">

        <div slot="header" class="mb-4">

          <div class="vx-row no-gutter">

            <!-- Month Name -->
            <div class="vx-col w-1/3 items-center sm:flex hidden">
              <!-- Add new event button -->
              <vs-button icon-pack="feather" icon="icon-plus" @click="promptAddNewEvent(new Date())">{{ $t('adicionar') }}</vs-button>
            </div>

            <!-- Current Month -->
            <div class="vx-col sm:w-1/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last">
              <div class="flex items-center">
                <feather-icon
                  :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                  @click="updateMonth(-1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full" />

                <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{ showDate | month }}</span>

                <feather-icon
                  :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                  @click="updateMonth(1)"
                  svgClasses="w-5 h-5 m-1"
                  class="cursor-pointer bg-primary text-white rounded-full" />
              </div>
            </div>

            <div class="vx-col sm:w-1/3 w-full flex justify-center">
              <template v-for="(view, index) in calendarViewTypes">
                <vs-button
                  v-if="calendarView === view.val"
                  :key="String(view.val) + 'filled'"
                  type="filled"
                  class="p-3 md:px-8 md:py-3"
                  :class="{'border-l-0 rounded-l-none': index, 'rounded-r-none': calendarViewTypes.length !== index+1}"
                  style="padding-left: 1rem !important;"
                  @click="calendarView = view.val"
                  >{{ view.shortLabel }}</vs-button>
                <vs-button
                  v-else
                  :key="String(view.val) + 'border'"
                  type="border"
                  class="p-3 md:px-8 md:py-3"
                  style="padding-left: 1rem !important;"
                  :class="{'border-l-0 rounded-l-none': index, 'rounded-r-none': calendarViewTypes.length !== index+1}"
                  @click="calendarView = view.val"
                  v-tooltip.top="{
                    content: view.label,
                    delay: { show: 1000, hide: 100 }
                  }"
                  >{{ view.shortLabel }}</vs-button>
              </template>

            </div>
          </div>

          <div class="vx-row sm:flex hidden mt-4">
            <div class="vx-col w-full flex">
              <!-- Labels -->
              <div class="flex flex-wrap sm:justify-start justify-center">
                  <div v-for="(label, index) in calendarLabels" :key="index" class="flex items-center mr-4 mb-2">
                      <div class="h-3 w-3 inline-block rounded-full mr-2" :class="'bg-' + label.color"></div>
                      <span>{{ label.text }}</span>
                  </div>
                  <div class="flex items-center mr-4 mb-2">
                      <div class="h-3 w-3 inline-block rounded-full mr-2 bg-primary"></div>
                      <span>{{ $t('none') }}</span>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </calendar-view>
    </div>

    <!-- ADD EVENT -->
    <vs-prompt
        class="calendar-event-dialog"
        :title="$t('adicionar-evento')"
        cancel-text = "Cancelar"
        accept-text= "Adicionar"
        @accept="addEvent"
        :is-valid="validForm"
        :active.sync="activePromptAddEvent">

        <vs-input name="event-name" v-validate="'required'" class="w-full" label-placeholder="Título do evento" v-model="title"></vs-input>
        <div class="my-4">
            <small class="date-label">{{ $t('data-inicio') }}</small>
            <datepicker :language="$vs.rtl ? langEn : langPt" name="start-date" v-model="startDate" :disabled="disabledFrom"></datepicker>
        </div>
        <div class="my-4">
            <small class="date-label">{{ $t('data-fim') }}</small>
            <datepicker :language="$vs.rtl ? langEn : langPt" :disabledDates="disabledDatesTo" name="end-date" v-model="endDate"></datepicker>
        </div>
        <vs-input name="event-url" v-validate="'url'" class="w-full mt-6" label-placeholder="URL do evento" v-model="url" :color="!errors.has('event-url') ? 'success' : 'danger'"></vs-input>

    </vs-prompt>

    <!-- EDIT EVENT -->
    <vs-prompt
        class="calendar-event-dialog"
        :title="$t('editar-evento')"
        accept-text= "Enviar"
        cancel-text = "Remover"
        button-cancel = "border"
        @cancel="removeEvent"
        @accept="editEvent"
        :is-valid="validForm"
        :active.sync="activePromptEditEvent">

        <vs-input name="event-name" v-validate="'required'" class="w-full" label-placeholder="Event Title" v-model="title"></vs-input>
        <div class="my-4">
            <small class="date-label">{{ $t('start-date') }}</small>
            <datepicker :language="langPt" :disabledDates="disabledDatesFrom" name="start-date" v-model="startDate"></datepicker>
        </div>
        <div class="my-4">
            <small class="date-label">{{ $t('end-date') }}</small>
            <datepicker :language="langPt" :disabledDates="disabledDatesTo" name="end-date" v-model="endDate"></datepicker>
        </div>
        <vs-input name="event-url" v-validate="'url'" class="w-full mt-6" label-placeholder="Event URL" v-model="url" :color="!errors.has('event-url') ? 'success' : 'danger'"></vs-input>

    </vs-prompt>

    <!-- VIEW EVENT -->
    <vs-popup
        class="calendar-event-dialog"
        :title="$t('questionario-agendado')"
        :active.sync="activePromptViewEvent"
    >
      <div class="grid grid-cols-12">
        <span class="text-lg text-center font-bold col-span-12 border-grey border-b border-t-0 border-r-0 border-l-0 border-solid">{{title}}</span>
        <div class="my-4 col-span-12 justify-self-start">
          <span>{{ $t('fica-disponivel-de') }}</span>
          <span class="mx-2 font-bold">{{startDate}}</span>
          <span>{{ $t('ate') }}</span>
          <span class="mx-2 font-bold">{{endDate}}</span>
        </div>
        <vs-button 
          class="justify-self-end col-span-12"
          color="primary"
          @click="openEventLink"
        >{{ $t('abrir') }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import CalendarService from '@/services/api/CalendarService'
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'
import moduleCalendar from '@/store/calendar/moduleCalendar.js'
require('vue-simple-calendar/static/css/default.css')

import Datepicker from 'vuejs-datepicker'
import { ptBR, en } from 'vuejs-datepicker/src/locale'

export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    Datepicker
  },
  data () {
    return {
      showDate: new Date(),
      disabledFrom: false,
      id: 0,
      title: '',
      startDate: '',
      endDate: '',
      type: '',

      langEn: en,
      langPt: ptBR,

      url: '',
      calendarView: 'month',

      activePromptAddEvent: false,
      activePromptEditEvent: false,
      activePromptViewEvent: false,

      calendarViewTypes: [
        {
          label: this.$t('mes'),
          shortLabel: this.$t('mes-0'),
          val: 'month'
        },
        {
          label: this.$t('semana'),
          shortLabel: this.$t('sem'),
          val: 'week'
        },
        {
          label: this.$t('validation.attributes.year'),
          shortLabel: this.$t('validation.attributes.year'),
          val: 'year'
        }
      ]
    }
  },
  computed: {
    simpleCalendarEvents () {
      return this.$store.state.calendar.events
    },
    validForm () {
      return this.title !== '' && this.startDate !== '' && this.endDate !== '' && Date.parse(this.endDate) - Date.parse(this.startDate) >= 0 && !this.errors.has('event-url')
    },
    disabledDatesTo () {
      return { to: new Date(this.startDate) }
    },
    disabledDatesFrom () {
      return { from: new Date(this.endDate) }
    },
    calendarLabels () {
      return this.$store.state.calendar.eventLabels
    },
    labelColor () {
      return (label) => {
        if      (label === 'business') return 'success'
        else if (label === 'work')     return 'warning'
        else if (label === 'personal') return 'danger'
        else if (label === 'none')     return 'primary'
      }
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    addEvent () {
      const obj = { title: this.title, startDate: this.startDate, endDate: this.endDate, url: this.url }
      this.$store.dispatch('calendar/addEvent', obj)
    },
    updateMonth (val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val)
    },
    clearFields () {
      this.title = this.endDate = this.url = ''
      this.id = 0
      this.labelLocal = 'none'
    },
    promptAddNewEvent (date) {
      this.disabledFrom = false
      this.addNewEventDialog(date)
    },
    addNewEventDialog (date) {
      this.clearFields()
      this.startDate = date
      this.endDate = date
      this.activePromptAddEvent = true
    },
    openAddNewEvent (date) {
      this.disabledFrom = true
      this.addNewEventDialog(date)
    },
    bufferEvent(event) {
      const e = event.originalEvent
      this.id = e.id
      this.title = e.title
      this.startDate = this.$utils.format.date(this.$moment(e.startDate))
      this.endDate = this.$utils.format.date(this.$moment(e.endDate))
      this.url = e.url
      this.type = e.type
    },
    handleEventClick (event) {
      if (event.originalEvent.edit !== false) {
        this.openEditEvent(event)
      } else {
        this.openViewEvent(event)
      }
    },
    openEventLink() {
      if (!this.type) {
        window.open(this.url, '_blank')
      } else if (this.type === 'App\\Models\\Cnt\\ContentQuestionnaire') {
        this.openQuestionnaire()
      }
    },
    openQuestionnaire() {
      const routeData = this.$router.resolve({
        name: 'questionnaires_form_answers',
        params: { short_url_id: this.url },
      })
      window.open(routeData.href, '_blank')
    },
    openEditEvent(event) {      
      this.bufferEvent(event)
      this.activePromptEditEvent = true
    },
    openViewEvent(event) {
      this.bufferEvent(event)
      this.activePromptViewEvent = true
    },
    editEvent () {
      const event = {
        id: this.id,
        data: { title: this.title, startDate: this.startDate, endDate: this.endDate, url: this.url }
      }
      this.$store.dispatch('calendar/editEvent', event)
    },
    removeEvent () {
      this.$store.dispatch('calendar/removeEvent', this.id)
    },
    eventDragged (event, date) {
      this.$store.dispatch('calendar/eventDragged', {event, date})
    }
  },
  created () {
    this.$store.registerModule('calendar', moduleCalendar)
    this.$store.dispatch('calendar/bootService', CalendarService.build(this.$vs))
    this.$store.dispatch('calendar/fetchEvents')
  },
  beforeDestroy () {
    this.$store.unregisterModule('calendar')
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/simple-calendar.scss";
</style>
